<template>
  <div>
    <a-form
      class="simiot-nested-search-form"
      :form="form"
      @submit="handleSearch"
    >
      <a-row :gutter="24">
        <a-col :span="8" :xxl="6">
          <a-form-item>
            <a-input
              v-decorator="['iccid_or_phone_number', {
                normalize: this.$lodash.trim
              }]"
              placeholder="请输入ICCID或SIMID或手机号"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="所属客户">
            <a-select
              v-decorator="['agent_id']"
              :show-arrow="false"
              show-search
              allow-clear
              :filter-option="false"
              :not-found-content="loadingAgentOptions ? undefined : null"
              label-in-value
              placeholder="请输入客户名称加载选项"
              @search="fetchAgentOptions"
              :loading="loadingAgentOptions"
            >
              <a-spin v-if="loadingAgentOptions" slot="notFoundContent" size="small" />
              <a-select-option
                v-for="option in agentOptions"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="生命周期">
            <a-select
              v-decorator="['life_cycle']"
              allow-clear
              @focus="fetchSimCardLifeCycleOptions"
              :loading="loadingLifeCycleOptions"
            >
              <a-select-option
                v-for="option in lifeCycleOptions"
                :key="option.slug"
                :value="option.slug"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="联网状态">
            <a-select
              v-decorator="['net_status']"
              allow-clear
              @focus="fetchNetStatusOptions"
              :loading="loadingNetStatusOptions"
            >
              <a-select-option
                v-for="option in netStatusOptions"
                :key="option.slug"
                :value="option.slug"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="用户业务模式">
            <a-select
              v-decorator="['user_business_type']"
              allow-clear
            >
              <a-select-option
                v-for="option in userBusinessTypeOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="销卡类型">
            <a-select
              v-decorator="['cancel_card_type']"
              allow-clear
            >
              <a-select-option
                v-for="option in cancelCardTypeOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
              <a-button :style="{ fontSize: '12px', lineHeight: '32px' }" @click="toggle">
                更多条件
                <a-icon :type="expand ? 'up' : 'down'" />
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import {
  findLifeCycleOptions,
  findNetStatusOptions,
  findUserBusinessTypeOptions,
  findCancelCardTypeOptions
} from '@/api/common_options'
import { findAgentOptions } from '@/api/agent'
export default {
  name: 'SearchCarrierAccountExpectCancelCardList',
  data() {
    return {
      expand: false,
      userBusinessTypeOptions: findUserBusinessTypeOptions(),
      cancelCardTypeOptions: findCancelCardTypeOptions(),
      lifeCycleOptions: [],
      netStatusOptions: [],
      agentOptions: [],
      loadingLifeCycleOptions: false,
      loadingNetStatusOptions: false,
      loadingAgentOptions: false,
      form: this.$form.createForm(this, { name: 'expect_candle_sim_card_search' })
    }
  },
  methods: {
    toggle() {
      this.expand = !this.expand
    },

    // 加载生命周期选项数据
    fetchSimCardLifeCycleOptions() {
      if (this.lifeCycleOptions.length === 0) {
        this.loadingLifeCycleOptions = true
        findLifeCycleOptions().then(res => {
          if (res.code === 0) {
            this.lifeCycleOptions = res.data
            this.loadingLifeCycleOptions = false
          }
        })
      }
    },

    // 加载联网状态选项数据
    fetchNetStatusOptions() {
      if (this.netStatusOptions.length === 0) {
        this.loadingNetStatusOptions = true
        findNetStatusOptions().then(res => {
          if (res.code === 0) {
            this.netStatusOptions = res.data
            this.loadingNetStatusOptions = false
          }
        })
      }
    },

    // 加载所属用户选项数据
    fetchAgentOptions(name) {
      if (!this.$lodash.trim(name)) {
        return
      }

      this.loadingAgentOptions = true
      findAgentOptions({ name: name }).then(res => {
        if (res.code === 0) {
          this.agentOptions = res.data
          this.loadingAgentOptions = false
        }
      })
    },

    handleSearch(e) {
      e.preventDefault()
      const fieldsValue = this.form.getFieldsValue()
      const values = {
        ...fieldsValue,
        agent_id: fieldsValue.agent_id && fieldsValue.agent_id.key
      }
      this.$emit('submit', values)
    },

    handleReset() {
      // 清空选项
      this.carrierAccountOptions = []
      this.agentOptions = []
      this.form.resetFields()
      this.$emit('submit', {})
    }

  }
}
</script>
