<template>
  <div>
    <search-carrier-account-expect-cancel-card-list
      @submit="submitSearch"
    />
    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="showUnicomCancelModal" v-if="isShowCancelButton('china_unicom')">联通销卡</a-button>
        <a-button type="primary" @click="showMobileCancelModal" v-if="isShowCancelButton('china_mobile')">移动销卡</a-button>
        <a-button type="primary" @click="showTelecomCancelModal" v-if="isShowCancelButton('china_telecom')">电信销卡</a-button>
        <a-button type="primary" @click="exportData">导出</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      @change="sortChange"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      row-key="iccid"
    />

    <!-- 分页 -->
    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <telecom-cancel-modal
      v-if="isShowTelecomCancelModal"
      :visible.sync="isShowTelecomCancelModal"
      :iccids.sync="selectedRowKeys"
      :id="carrierAccountId"
    />
    <mobile-cancel-modal
      v-if="isShowMobileCancelModal"
      :visible.sync="isShowMobileCancelModal"
      :iccids.sync="selectedRowKeys"
      :id="carrierAccountId"
    />
    <unicom-cancel-modal
      v-if="isShowUnicomCancelModal"
      :visible.sync="isShowUnicomCancelModal"
      :iccids.sync="selectedRowKeys"
      :id="carrierAccountId"
    />
  </div>
</template>

<script>
import { findCarrierAccountName, findCarrierType } from '@/api/carrier_account'
import SearchCarrierAccountExpectCancelCardList from '@/views/cancel_card_manages/expect_cancel_card_list/Search'
import Pagination from '@/components/Pagination'
import {
  findExpectCancelCardListByCarrierAccount,
  exportExpectCancelSimCardsByCarrierAccount
} from '@/api/cancel_card_manage'
import { exportExcel } from '@/api/excel'
export default {
  name: 'ShowCarrierAccountExpectCancelCardList',
  components: {
    SearchCarrierAccountExpectCancelCardList,
    Pagination,
    TelecomCancelModal: () => import('@/views/cancel_card_manages/expect_cancel_card_list/china_telecom/index'),
    MobileCancelModal: () => import('@/views/cancel_card_manages/expect_cancel_card_list/china_mobile/index'),
    UnicomCancelModal: () => import('@/views/cancel_card_manages/expect_cancel_card_list/china_unicom/index')
  },
  data() {
    return {
      data: [],
      loading: false,
      isShowTelecomCancelModal: false,
      isShowMobileCancelModal: false,
      isShowUnicomCancelModal: false,
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      },
      selectedRowKeys: [],
      carrierType: ''
    }
  },
  watch: {
    carrierAccountId() {
      this.setBreadcrumb()
    }
  },
  created() {
    this.setBreadcrumb()
    this.fetchCarrierType()
    this.fetchData()
  },
  computed: {
    carrierAccountId() {
      return parseInt(this.$route.params.id)
    },

    columns() {
      return [
        {
          title: 'ICCID/SIMID',
          dataIndex: 'iccid',
          fixed: 'left'
        },
        {
          title: '手机号码',
          dataIndex: 'phone_number'
        },
        {
          title: '运营商账户',
          dataIndex: 'carrier_account_name'
        },
        {
          title: '客户',
          dataIndex: 'top_agent_name'
        },
        {
          title: '用户业务模式',
          dataIndex: 'user_business_type'
        },
        {
          title: '生命周期',
          dataIndex: 'life_cycle'
        },
        {
          title: '联网状态',
          dataIndex: 'net_status'
        },
        {
          title: '运营商状态',
          dataIndex: 'card_status'
        },
        {
          title: '服务周期结束时间',
          dataIndex: 'service_end_time'
        },
        {
          title: '预计销卡时间',
          dataIndex: 'expect_cancel_time',
          sorter: true,
          sortOrder: this.sort.sort_field === 'expect_cancel_time' ? this.sort.sort_order : false
        },
        {
          title: '销卡类型',
          dataIndex: 'cancel_card_type'
        }
      ]
    }
  },
  methods: {
    showTelecomCancelModal() {
      this.isShowTelecomCancelModal = true
    },

    showMobileCancelModal() {
      this.isShowMobileCancelModal = true
    },

    showUnicomCancelModal() {
      this.isShowUnicomCancelModal = true
    },

    isShowCancelButton(carrierType) {
      return this.carrierType === carrierType
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    setBreadcrumb() {
      findCarrierAccountName(this.carrierAccountId).then((res) => {
        if (res.code === 0) {
          this.$store.dispatch('SetDynamicBreadCrumbsPlaceholder', { ':carrier_account_name': res.data.name })
        }
      })
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      this.selectedRowKeys = []
      findExpectCancelCardListByCarrierAccount(Object.assign({}, this.query, this.sort), this.carrierAccountId).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    fetchCarrierType() {
      findCarrierType(this.carrierAccountId).then((res) => {
        if (res.code === 0) {
          this.carrierType = res.data.carrier_type
        }
      })
    },

    exportData() {
      exportExpectCancelSimCardsByCarrierAccount(this.carrierAccountId, this.query).then((res) => {
        if (res.code === 0 && res.data.is_direct_download) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>
